import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import {
  Routes, Route, useNavigate 
} from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { ScrollToTopWhenLocationChanged } from './features/app/ScrollToTopWhenLocationChanged'
import { GlobalStyle } from './ui/styled.global'
import {
  DARK_THEME, LIGHT_THEME 
} from './constants/themes'
import { HomePage } from './pages/Home'


function App() {
  const currentTheme = window.Telegram.WebApp.colorScheme === 'dark' ? DARK_THEME : LIGHT_THEME

  return (
    <ThemeProvider theme={currentTheme}>
      <ScrollToTopWhenLocationChanged>
        <Routes>
          <Route
            path="/"
            element={<HomePage />}
          />
        </Routes>

        <GlobalStyle />
      </ScrollToTopWhenLocationChanged>
    </ThemeProvider>
  )
}

export default App
