import React from 'react'

import { useMonth } from '@datepicker-react/hooks'
import styled from 'styled-components'
import arrowImg from 'src/images/icons/arrow-right.png'
import {
  format, parse 
} from 'date-fns'
import { ru } from 'date-fns/locale'

import Day from './Day'

function Month({
  year, month, firstDayOfWeek, onPrev, onNext, availableDates 
}) {
  const {
    days, weekdayLabels, monthLabel 
  } = useMonth({
    year,
    month,
    firstDayOfWeek,
    monthLabelFormat: (date) => format(date, 'LLLL yyyy', { locale: ru }),
    weekdayLabelFormat: (date) => format(date, 'eee', { locale: ru }),
  })

  return (
    <MonthBox>
      <TopRow>
        <ArrowImg
          src={arrowImg}
          onClick={onPrev}
          className="left" 
        />

        <strong>{monthLabel}</strong>

        <ArrowImg
          src={arrowImg}
          onClick={onNext}
        />
      </TopRow>

      <WeekDaysBox >
        {weekdayLabels.map(dayLabel => (
          <WeekDay
            key={dayLabel}
          >
            {dayLabel}
          </WeekDay>
        ))}
      </WeekDaysBox>

      <DaysBox>
        {days.map(day => (
          <Day
            date={day.date}
            key={day.dayLabel}
            day={day.dayLabel}
          />
        ))}
      </DaysBox>
    </MonthBox>
  )
}

const MonthBox = styled.div``

const TopRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #037EE5;
  text-transform: capitalize;
`

const ArrowImg = styled.img`
  width: 18px;
  height: 18px;
  object-fit: contain;

  &.left {
    transform: rotate(180deg);
  }
`

const WeekDaysBox = styled.div`
  margin-top: 25px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-content: center;
`

const WeekDay = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  color: #7E7E82;
  text-transform: uppercase;
`

const DaysBox = styled.div`
  margin-top: 24px;
  grid-template-columns: repeat(7, 1fr);
  justify-content: center;
  display: grid;
`

export default Month
