import React, {
  useEffect, useState 
} from 'react'

import { format } from 'date-fns'
import { START_DATE } from '@datepicker-react/hooks'
import { useDispatch } from 'react-redux'
import { OrderBtn } from 'src/features/basket/BasketBtn'
import Datepicker from 'src/ui/datepicker/Datepicker'
import styled from 'styled-components'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { CheckboxSelectIOS } from 'src/ui/CheckboxSelectIOS'
import {
  InputGroupIOS, InputIOS 
} from 'src/ui/InputIOS'
import { Counter } from 'src/ui/Counter'
import { Switch } from 'src/ui/Switch'
import noActorImg from 'src/images/no-actor.png'
import actorImg from 'src/images/actor.png'
import ghostImg from 'src/images/ghost.png'
import childrenImg from 'src/images/children.png'
import noActorLightImg from 'src/images/no-actor-light.png'
import actorLightImg from 'src/images/actor-light.png'
import ghostLightImg from 'src/images/ghost-light.png'
import childrenLightImg from 'src/images/children-light.png'
import { api } from 'src/features/api'

const TIME_SLOTS = [
  '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '00:00'
]

const PRICE_SLOTS = [
  '3000 ₽', '5000 ₽', '7000 ₽', '10000 ₽', '12000 ₽', '15000 ₽', '18000 ₽', '>20000',
]

const AREAS = [
  'Все районы Москвы', 'Восточный (ВАО)', 'Западный (ЗАО)', 'Зеленоградский (ЗелАО)', 'Новомосковский (НМАО)', 'Северный (САО)',
  'Северо-Восточный (СВАО)', 'Северо-Западный (СЗАО)', 'Троицкий (ТАО)', 'Центральный (ЦАО)', 'Юго-Восточный (ЮВАО)', 'Юго-Западный (ЮЗАО)', 
  'Южный (ЮАО)'
]

const checkSlotActiveStatus = (slot, allSlots, selectedSlots) => {
  if (selectedSlots.length === 0) {
    return false
  }

  if (selectedSlots.length === 1) {
    return slot === selectedSlots[0]
  }

  return allSlots.indexOf(slot) >= allSlots.indexOf(selectedSlots[0]) && allSlots.indexOf(slot) <= allSlots.indexOf(selectedSlots[1])
}

export const HomePage = ({ className = '' }) => {
  const [datepickerState, setDatepickerState] = useState({
    startDate: null,
    endDate: null,
    focusedInput: START_DATE
  })

  const [userData, setUserData] = useState({
    name: '',
    phone: ''
  })

  const [types, setTypes] = useState([])
  const [levels, setLevels] = useState([])
  const [areas, setAreas] = useState([])
  const [playersCount, setPlayersCount] = useState(1)
  const [needCall, toggleNeedCall] = useState(false)
  const [currentSlot, setCurrentSlot] = useState(null)
  const [timeSlots, setTimeSlots] = useState([])
  const [priceSlots, setPriceSlots] = useState([])

  const [success, toggleSuccess] = useState(false)

  const availableDates = Object.keys(timeSlots).map((key) => key)

  const handleSetDatepickerState = (...args) => {
    setDatepickerState(...args)
  }

  const handleSelectTypes = (type) => {
    const index = types.findIndex(t => type === t)

    if (index === -1) {
      setTypes([...types, type])
    } else {
      setTypes([...types.slice(0, index), ...types.slice(index + 1)])
    }
  }

  const handleSelectLevels = (level) => {
    const index = levels.findIndex(l => level === l)

    if (index === -1) {
      setLevels([...levels, level])
    } else {
      setLevels([...levels.slice(0, index), ...levels.slice(index + 1)])
    }
  }

  const handleSelectAreas = (area) => {
    const index = areas.findIndex(l => area === l)

    if (AREAS[0] === area) {
      setAreas([area])

      return
    }

    if (areas[0] === AREAS[0]) {
      setAreas([])
    }

    if (index === -1) {
      setAreas([...(areas[0] === AREAS[0] ? areas.slice(1) : areas), area])
    } else {
      setAreas([...(areas[0] === AREAS[0] ? areas.slice(1).slice(0, index) : areas.slice(0, index)), ...areas.slice(index + 1)])
    }
  }

  const handleSelectTimeSlots = (slot) => {
    if (timeSlots.length === 2 || timeSlots.length === 0) {
      setTimeSlots([slot])

      return
    }

    const indexOfCurrentSlot = TIME_SLOTS.indexOf(timeSlots[0])
    const indexOfNewSlot = TIME_SLOTS.indexOf(slot)

    if (indexOfCurrentSlot > indexOfNewSlot) {
      setTimeSlots([slot, timeSlots[0]])
    } else {
      setTimeSlots([timeSlots[0], slot])
    }
  }

  const handleSelectPriceSlots = (slot) => {
    if (priceSlots.length === 2 || priceSlots.length === 0) {
      setPriceSlots([slot])

      return
    }

    const indexOfCurrentSlot = PRICE_SLOTS.indexOf(priceSlots[0])
    const indexOfNewSlot = PRICE_SLOTS.indexOf(slot)

    if (indexOfCurrentSlot > indexOfNewSlot) {
      setPriceSlots([slot, priceSlots[0]])
    } else {
      setPriceSlots([priceSlots[0], slot])
    }
  }

  const handleSubmit = () => {
    const data = {
      dateFrom: !datepickerState.startDate ? undefined : format(datepickerState.startDate, 'yyyy-MM-dd'),
      dateTo: !datepickerState.startDate ? undefined : datepickerState.endDate ? format(datepickerState.endDate, 'yyyy-MM-dd') : format(datepickerState.startDate, 'yyyy-MM-dd'),
      timeFrom: !timeSlots.length ? undefined : timeSlots[0] + ':00.000',
      timeTo: !timeSlots.length ? undefined : (timeSlots[1] ? timeSlots[1] : timeSlots[0]) + ':00.000',
      priceFrom: !priceSlots.length ? undefined : priceSlots[0],
      priceTo: !priceSlots.length ? undefined : priceSlots[1] ? priceSlots[1] : priceSlots[0],
      withActor: (types.length === 2 || types.length === 0) ? null : types.includes('С актером'),
      isScary: levels.includes('Страшный'),
      isChildren: levels.includes('Детский'),
      membersCount: playersCount,
      district: areas.join(','),
      name: userData.name,
      phone: userData.phone,
      isRecall: needCall
    }

    api.sendRequest(data)

    toggleSuccess(true)
  }

  const handleClose = () => {
    window?.Telegram?.WebApp?.close()
  }

  const disabled = !userData.phone || userData.phone.length < 12

  if (success) {
    return (
      <SuccessBox>
        <Title>
          Подбор квеста
        </Title>

        <SuccessContent>
          <SuccessTitle>
        Заявка отправлена.
          </SuccessTitle>
          <SuccessDescription>
        В ближайшее время с вами  <br />
        свяжется наш менеджер.
          </SuccessDescription>
        </SuccessContent>
        
        <OrderBtn
          text="Закрыть"
          onClick={handleClose}
        />
      </SuccessBox>
    )
  }

  return (
    <HomePageBox className={className}>
      <TitleBox>
        <Title>
          Подбор квеста
        </Title>
      </TitleBox>
      
      <ContactsBox>
        <InputGroupLabel className="no-margin">
          Контакты
        </InputGroupLabel>
        <InputGroupIOS>
          <InputIOS
              label="Имя"
              value={userData.name}
              onChange={(e) => {
                const regexp = /[^a-zа-яё_\-\/=\s]/gi
                const value = e.target.value
                setUserData(state => ({
                  ...state,
                  name: value.replace(regexp, '').substring(0, 25)
                }))}}
          />
          <InputIOS
              label="Телефон"
              value={userData.phone}
              mask="+79999999999"
              onChange={(e) => {
                const regexp = /[^0-9]/gi
                const value = e.target.value
                setUserData(state => ({
                  ...state,
                  phone: '+' + value.replace(regexp, '').substring(0, 11).trim()
                }))}}
          />
        </InputGroupIOS>
      </ContactsBox>

      <CalendarBox>
        <Label>
          Дата (интервал)
        </Label>

        <Datepicker
          availableDates={availableDates}
          datepickerState={datepickerState}
          onSetState={handleSetDatepickerState}
        />

        <OrderBtn
          text="Подобрать"
          disabled={disabled}
          onClick={handleSubmit}
        />

        <Label>
          Время (интервал)
        </Label>
        
        <TimeSlotsBox>
          {TIME_SLOTS.map(slot => (
            <TimeSlot
              key={slot.id}
              onClick={() => handleSelectTimeSlots(slot)}
              className={classNames({ active: checkSlotActiveStatus(slot, TIME_SLOTS, timeSlots) })}
            >
              {slot}
            </TimeSlot>
          ))}
        </TimeSlotsBox>

        <Label>
        Стоимость (интервал)
        </Label>
        
        <TimeSlotsBox>
          {PRICE_SLOTS.map(slot => (
            <TimeSlot
              key={slot.id}
              onClick={() => handleSelectPriceSlots(slot)}
              className={classNames({ active: checkSlotActiveStatus(slot, PRICE_SLOTS, priceSlots) })}
            >
              {slot}
            </TimeSlot>
          ))}
        </TimeSlotsBox>
      </CalendarBox>

      <FormBox>
        <InputGroupLabel>
      Вид квеста
        </InputGroupLabel>

        <CheckboxSelectIOS
          options={[{
            label: 'Без актера', value: 'Без актера', img: window.Telegram.WebApp.colorScheme === 'dark' ? noActorLightImg : noActorImg 
          }, {
            label: 'С актером', value: 'С актером', img: window.Telegram.WebApp.colorScheme === 'dark' ? actorLightImg : actorImg  
          }]}
          onChange={handleSelectTypes}
          checked={types}
        />

        <InputGroupLabel>
        Уровень (необязательно)
        </InputGroupLabel>

        <CheckboxSelectIOS
          options={[{
            label: 'Страшный', value: 'Страшный', img: window.Telegram.WebApp.colorScheme === 'dark' ? ghostLightImg : ghostImg   
          }, {
            label: 'Детский', value: 'Детский', img: window.Telegram.WebApp.colorScheme === 'dark' ? childrenLightImg : childrenImg    
          }]}
          onChange={handleSelectLevels}
          checked={levels}
        />

        <InputGroupLabel>
          Участники
        </InputGroupLabel>

        <InputGroupIOS>
          <CounterBox>
        Количество участников

            <Counter
              size="small"
              count={playersCount}
              onPlus={() => setPlayersCount(state => state + 1)}
              onMinus={() => setPlayersCount(state => state === 1 ? state : state - 1)}
            />
          </CounterBox>
          
          
        </InputGroupIOS>

        <InputGroupLabel>
        Предпочтительный Район москвы
        </InputGroupLabel>

        <CheckboxSelectIOS 
          options={AREAS.map(area => ({
            label: area, value: area 
          }))}
          checked={areas}
          onChange={handleSelectAreas}
        />

        <InputGroupLabel>
          Способ связи
        </InputGroupLabel>

        <InputGroupIOS>
          <SwitchRow>
          Звонок на телефон
            <Switch
              checked={needCall}
              onChange={() => toggleNeedCall(!needCall)}
            />
          </SwitchRow>
        </InputGroupIOS>

        <Rules>
        Нажимая на кнопку «Подобрать», я принимаю <a href="#">пользовательское соглашение</a> и <a href="#">политику конфиденциальности</a>
        </Rules>
      </FormBox>
      
    </HomePageBox>
  )
}

const HomePageBox = styled.div`
  background: ${props => props.theme.backgrounds.secondary};
  padding-bottom: 116px;
`

const CalendarBox = styled.div`
  background: ${props => props.theme.backgrounds.main};
  padding: 16px 16px;
`

const TitleBox = styled.div`
  background: ${props => props.theme.backgrounds.main};
  padding: 16px 16px;
`

const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: ${props => props.theme.text.main};
  text-align: center;
`

const DayName = styled.p`
  margin-top: 32px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #037EE5;
`

const TimeSlotsBox = styled.div`
  margin: 24px auto 0;
  display: grid;
  grid-template-columns: repeat(4, 70px);
  grid-gap: 18px 16px;
  width: fit-content;
`

const TimeSlot = styled.div`
  border: 1px solid #C6C6C6;
  border-radius: 4px;
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 15px;
  line-height: 1;
  color: ${props => props.theme.text.main};

  &.active {
    background: #037EE5;
    color: white;
  }
`

const Label = styled.p`
  margin-top: 28px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.15px;
  text-transform: uppercase;
  color: #636366;
`

const InputGroupLabel = styled.p`
  margin-top: 28px;
  margin-bottom: 7px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.15px;
  text-transform: uppercase;
  color: #636366;
  
  &.no-margin {
    margin-top: 0;
  }
`

const FormBox = styled.div`
  padding: 0 16px;
`

const ContactsBox = styled.div`
  padding: 20px 16px;
`

const CounterBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 21px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: ${props => props.theme.text.main};
`

const Rules = styled.p`
  margin: 20px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.4px;
  color: ${props => props.theme.text.main};
  padding: 0 5px;

  a {
    color: ${props => props.theme.text.tertiary};
    text-decoration: underline;
  }
`

const SwitchRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 6px 6px 24px;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  color: ${props => props.theme.text.main};
`

const SuccessBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 116px;
  height: 100vh;
  padding-top: 20px;
`

const SuccessTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: ${props => props.theme.text.main};
`

const SuccessDescription = styled.p`
  margin-top: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: rgba(99, 99, 102, 1);
`

const SuccessContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`